import moment from "moment-timezone";

// Queries
import FETCH_PROGRAMS from "../../graphql/query/FetchPrograms.gql";
import FETCH_PROGRAM_DETAILS from "../../graphql/query/FetchProgramDetails.gql";
import RUN_PROGRAM_ALGORITHM from "../../graphql/query/FetchAlgorithmResults.gql";
import FETCH_FEATURE_BY_NAME from "../../graphql/query/FetchFeatureByName.gql";
import FETCH_FEATURES_CONFIG from "../../graphql/query/FetchFeaturesConfig.gql";
import DOWNLOAD_PROGRAM_REPORT from "../../graphql/query/DownloadProgramReport.gql";

// Mutations
import CREATE_PROGRAM from "../../graphql/mutation/CreateProgram.gql";
import REMOVE_PROGRAM from "../../graphql/mutation/RemoveProgram.gql";
import UNLINK_COACH from "../../graphql/mutation/UnlinkCoach.gql";
import UPDATE_PROGRAM from "../../graphql/mutation/UpdateProgram.gql";

export const namespaced = true;

const initState = () => {
  return {
    programs: [],
    isProgramsLoading: false,
    features: [],
    featuresConfig: [],
  };
};

export const state = initState();

export const mutations = {
  SET_PROGRAMS(state, programs) {
    state.programs = programs;
  },
  SET_LOADING(state, status) {
    state.isProgramsLoading = status;
  },
  REMOVE_PROGRAM(state, removedProgramId) {
    state.programs = state.programs.filter(({ _id }) => _id !== removedProgramId);
  },
  SET_FEATURES_CONFIG(state, featuresConfig) {
    state.featuresConfig = featuresConfig;
  },
};

export const actions = {
  async createProgram({ commit }, { apolloClient, newProgram }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { CreateProgram: program },
      } = await apolloClient.mutate({
        mutation: CREATE_PROGRAM,
        fetchPolicy: "no-cache",
        variables: {
          data: newProgram,
        },
      });

      if (!program) throw new Error("No se pudo crear el programa");

      return program;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchFeaturesConfig({ commit }, { apolloClient }) {
    try {
      const {
        data: { FetchFeaturesConfig: featuresConfig },
      } = await apolloClient.query({
        query: FETCH_FEATURES_CONFIG,
        fetchPolicy: "no-cache",
      });
      commit("SET_FEATURES_CONFIG", featuresConfig);
      return featuresConfig;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchProgramDetails({ commit }, { _id, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { FetchProgramDetails: programDetails },
      } = await apolloClient.query({
        query: FETCH_PROGRAM_DETAILS,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            filter: {
              _id,
            },
          },
        },
      });
      return programDetails;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async runProgramAlgorithm({ commit }, { programId, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { FetchAlgorithmResults: AlgorithmResults },
      } = await apolloClient.query({
        query: RUN_PROGRAM_ALGORITHM,
        fetchPolicy: "no-cache",
        variables: {
          ID: programId,
        },
      });
      return AlgorithmResults;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchPrograms({ commit }, { filter, limit, skip, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { FetchPrograms: programs },
      } = await apolloClient.query({
        query: FETCH_PROGRAMS,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            filter,
            limit,
            skip,
          },
        },
      });

      commit("SET_PROGRAMS", programs);
      return programs;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async removeProgram({ commit }, { _id, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { RemoveProgram: removedProgram },
      } = await apolloClient.query({
        query: REMOVE_PROGRAM,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            _id,
          },
        },
      });
      if (!removedProgram._id)
        throw new Error("Hubo un error eliminando el programa");
      commit("REMOVE_PROGRAM", removedProgram._id);
      return removedProgram;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async unlinkCoach({ commit }, { programId, coachId, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { UnlinkCoach: updatedProgram },
      } = await apolloClient.mutate({
        mutation: UNLINK_COACH,
        fetchPolicy: "no-cache",
        variables: { coachId, programId },
      });
      return updatedProgram;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async updateProgram({ commit }, { data, _id, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { UpdateProgram: updatedProgram },
      } = await apolloClient.mutate({
        mutation: UPDATE_PROGRAM,
        fetchPolicy: "no-cache",
        variables: { data, _id },
      });
      return updatedProgram;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchFeatureByName({ commit }, { name, programId, apolloClient }) {
    try {
      const {
        data: { FetchFeatureByName: feature },
      } = await apolloClient.query({
        query: FETCH_FEATURE_BY_NAME,
        fetchPolicy: "no-cache",
        variables: {
          name,
          programId,
        },
      });
      return feature;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async generateReport({ commit }, { programId, apolloClient }) {
    try {
      const {
        data: { DownloadProgramReport },
      } = await apolloClient.query({
        query: DOWNLOAD_PROGRAM_REPORT,
        fetchPolicy: "no-cache",
        variables: {
          programId,
        },
      });
      return DownloadProgramReport;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getters = {
  getProgramById: (state, getters) => (programId) =>
    getters.programs?.find((program) => program?._id === programId) ?? null,
  isProgramsLoading: ({ isProgramsLoading }) => isProgramsLoading,
  programs: ({ programs }, getters, rootState, rootGetters) => {
    const userTimezone = rootGetters["users/getTimezone"];
    return programs.map((program) => ({
      ...program,
      expiresAt: moment.tz(+program.expiresAt, userTimezone),
      createdAt: moment.tz(+program.createdAt, userTimezone),
      updatedAt: moment.tz(+program.updatedAt, userTimezone),
    }));
  },
  features: (state) => state?.features || [],
  featuresConfig: (state) => state?.featuresConfig || [],
};
