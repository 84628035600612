<template>
  <v-app>
    <v-main>
      <AppLayout>
        <router-view :key="$route.path" />
      </AppLayout>

      <BaseScoreDialog v-if="shouldScoreRender" />
      <BaseScoreDialog v-if="shouldScoreLdpRender" />
      <BaseSnackbar />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppLayout from "@/layouts/AppLayout";
import getMetaInfo from "@/lib/meta";
import IS_EXPIRED from "@/graphql/query/isExpiredConnection.gql";
import BaseSnackbar from "@/components/Base/Snackbar";
import BaseScoreDialog from "@/components/Base/ScoreDialog";

export default {
  name: "App",
  components: {
    AppLayout,
    BaseSnackbar,
    BaseScoreDialog,
  },
  data() {
    return {
      isExpired: null,
    };
  },
  async created() {
    try {
      await this.getUser({ apolloClient: this.$apollo });
      const userId = this.user?._id || null;
      const userRole = this.user?._type.active || null;
      if (userId && userRole) {
        window.dataLayer.push({
          event: "user_identified",
          user_id: userId,
          role: userRole,
        });
      }
    } catch (error) {
      console.error("User not identified", error);
    }
  },
  computed: {
    ...mapGetters("users", ["isLoggedIn", "user", "isCoach", "activeRole", "userGroup"]),
    ...mapGetters("schedules", ["getSessionList"]),
    ...mapGetters("chat", ["getUnreadConversations"]),

    shouldScoreRender() {
      return (
        (this.userGroup === "collaborator" && this.activeRole === "coach") ||
        (this.userGroup === "customer" && this.activeRole === "coachee")
      );
    },
    shouldScoreLdpRender() {
      return this.userGroup === "customer" && this.activeRole === "admin";
    },
  },
  watch: {
    isLoggedIn() {
      if (!this.isLoggedIn) {
        if (!!this.$apollo.queries.isExpired)
          this.$apollo.queries.isExpired.stopPolling();
      } else {
        this.$apollo.addSmartQuery("isExpired", {
          pollInterval: process.env.VUE_APP_POLLINTERVAL_TIME,
          query: IS_EXPIRED,
          update: (data) => data.User.expireConnection,
        });
      }
    },
    isExpired() {
      if (this.isExpired) {
        if (!!this.$apollo.queries.isExpired)
          this.$apollo.queries.isExpired.stopPolling();
        this.signout();
        location.reload();
      }
    },
    user() {
      if (this.user) this.initializeUserConversations();
    },
  },
  metaInfo() {
    return {
      ...getMetaInfo(),
    };
  },
  async beforeMount() {
    try {
      const forms = await this.getForms({ apolloClient: this.$apollo });
      const token = localStorage.getItem("token");
      if (!this.isLoggedIn) {
        if (!!this.$apollo.queries.isExpired)
          this.$apollo.queries.isExpired.stopPolling();
      } else {
        this.$apollo.addSmartQuery("isExpired", {
          pollInterval: process.env.VUE_APP_POLLINTERVAL_TIME,
          query: IS_EXPIRED,
          update: (data) => data.User.expireConnection,
        });
        await this.fetchSessionList({
          apolloClient: this.$apollo,
          data: {},
        });

        await this.readyToTalk();
      }
    } catch (error) {
      console.error(error);
      if (error.length > 0 && error[0] === "Error de Autenticación") {
        this.$router.push({ name: "Signout" });
      }
    }
  },
  methods: {
    ...mapActions("schedules", ["fetchSessionList"]),
    ...mapActions("contents", ["getForms"]),
    ...mapActions("users", ["signout", "getUser"]),
    ...mapActions("chat", [
      "readyToTalk",
      "createSession",
      "createUser",
      "createConversation",
      "setUnread",
    ]),
    async initializeUserConversations() {
      try {
        this.me = await this.createUser(this.user);
        this.chatSession = await this.createSession({ me: this.me });
        this.inbox = this.chatSession.createChatbox();
        this.chatSession.unreads.onChange((unreadConversations) => {
          this.unreadConversation = [...unreadConversations];
          this.setUnread([...unreadConversations]);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.v-application p {
  margin-bottom: 0;
}
.main-app {
  max-width: 1200px !important;
  justify-content: flex-start;
}
</style>
